import { defineComponent, ref } from 'vue';
import BaseIcon from '../atoms/BaseIcon.vue';
export default defineComponent({
    name: 'LocationFilter',
    components: { BaseIcon },
    props: {
        countries: { type: Array, default: () => [], required: true },
        background: { type: String },
    },
    setup() {
        let activeTab = ref(true);
        const selectedCountry = ref('*');
        const onClickFilter = (btn) => {
            if (btn === 'map') {
                activeTab.value = true;
            }
            else {
                activeTab.value = false;
                setTimeout(function () {
                    onChangeCountry(selectedCountry.value);
                }, 100);
            }
        };
        const onChangeCountry = (country) => {
            const cities = document.querySelectorAll('.city-item');
            cities.forEach(function (el) {
                el.classList.remove('hide');
                const cityName = el.getAttribute('data-country-name');
                if (cityName !== country) {
                    el.classList.add('hide');
                }
                if (country === '*') {
                    el.classList.remove('hide');
                }
            });
        };
        const mapBtnActive = () => {
            const url = window.location.href;
            const deeplink = url.indexOf('#');
            if (deeplink > 0 && url.substring(deeplink + 1) === 'mapview') {
                setTimeout(function () {
                    const mapBtn = document.querySelector('div.location-filter__map-btn');
                    const listBtn = document.querySelector('div.location-filter__list-btn');
                    mapBtn?.classList.add('visible');
                    listBtn?.classList.remove('visible');
                    activeTab.value = true;
                }, 100);
            }
        };
        mapBtnActive();
        return {
            activeTab,
            selectedCountry,
            onClickFilter,
            onChangeCountry,
            mapBtnActive,
        };
    },
});
